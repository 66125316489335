<template>
  <main>
  </main>
</template>

<route>
{
"meta": {
"label": "個人設定"
}
}
</route>

<script>
export default {
  name: 'UMEANS_ECRM',
  layout: 'manage',
  computed: {
  },
  data() {
    return {
    }
  },
  mounted() {
    return this.$swal({
      icon: 'info',
      title: '即將跳轉至新版介面',
      text: '目前新版介面的功能會以會員經營、再行銷及訊息推播優先開發，我們接下來還有更多功能會在新版介面推出，原有的功能在未來我們也會優化後重新於新版介面推出！',
      showCancelButton: true,
      confirmButtonColor: '#2eb85c',
      confirmButtonText: this.$t('Global.Confirm'),
      cancelButtonText: this.$t('Global.Cancel')
    }).then((result) => {
      if (result.isConfirmed) {
        this.$store.dispatch('InnerRequest', {
          url: '/user/createCustomToken',
          method: 'get',
        }).then(({ data }) => {
          return window.open(`https://ecrm.umeans.net/callback?token=${data.Data}`)
        })
      }
    })
  },
  methods: {
  }
}
</script>
